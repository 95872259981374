import gitHub from "../res/GitHub-Mark-120px-plus.png";
import gitlab from "../res/gitlab-logo-500.png";
import linkedIn from "../res/In-Blue-72@2x.png";
import sanialarm from "../res/sanialarm_original.png";
import profilePicture from "../res/ProfilePicture.jpg";
import { Grid, Typography, IconButton, Avatar, Chip, Box, Button } from '@mui/material';
import { MailOutlineOutlined } from "@mui/icons-material";

let email = "privat@claudiuskienle.de";

function App() {
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            p={2}
            rowSpacing={3}
            style={{ minHeight: '100vh' }}
        >
            <Grid item sm={12} alignItems="center" display="flex" flexDirection="column">
                <Avatar src={profilePicture} sx={{ width: 128, height: 128 }} />
                <Box mb={1} />
                <Button href={"mailto:" + email} style={{textDecoration: "none", color: "black"}}>
                    <MailOutlineOutlined />
                    <Box ml={1} />
                    {email}
                </Button>
            </Grid>
            <Grid item sm={12} alignItems="center" display="flex" flexDirection="column">
                <Typography sx={{ typography: { sm: 'h1', xs: 'h2' } }} justifyContent="center" textAlign="center" >
                    Claudius Kienle
                </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center" mt={4}>
                <IconButton href="https://github.com/claudius-kienle"
                    target='_black'>
                    <img width={64} src={gitHub} />
                </IconButton>
                <IconButton href="https://gitlab.com/Claudi12213"
                    target='_black' sx={{ ml: 4, mr: 4 }}>
                    <img width={64} src={gitlab} />
                </IconButton>
                <IconButton href="https://www.linkedin.com/in/claudius-kienle-91a352161/"
                    target='_black'>
                    <img width={64} src={linkedIn} />
                </IconButton>
            </Grid>
            <Grid item xs={6} textAlign="center" display="flex" flexDirection="column">
                <IconButton style={{ textTransform: 'none', color: 'black' }} href="https://sanialarm.de" target="_blank">
                    <img width={64} src={sanialarm} />
                </IconButton>
                <Typography variant="caption">
                    SaniAlarm
                </Typography>
            </Grid>
        </Grid >
    );
}

export default App;
